import { createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import zipy from 'zipyai';
import {
  loginUser,
  checkExistingUser,
  signUpUser,
  forgotPassword,
  auth,
  getProductTours,
  addTourStatus,
  updateProfile,
  updateProfileProp,
  resetPassword,
  authCheck,
  logoutUser,
} from '../asyncActions/auth';
import {
  emailExistingObjType,
  forgotPasswordObjType,
  loginObjType,
  signUpObjType,
  authType,
  productToursType,
  TourStep,
} from './Types';
import { ENV } from '@/components/Common/Constants';

interface ICurrentTour {
  steps: Array<TourStep>;
  run: boolean;
  stepIndex: number;
  name: string;
}

interface UserState {
  loading: boolean;
  loginObj: loginObjType;
  existingUserObj: emailExistingObjType;
  signUpObj: signUpObjType;
  forgotPasswordObj: forgotPasswordObjType;
  loggedInUserObj: authType;
  productTours: Array<productToursType>;
  tourLoadingStatus: 'not started' | 'started' | 'completed';
  visibleTours: Array<string>;
  currentTour: ICurrentTour;
  showPolicy: boolean;
  resetPassword?: boolean | null;
  resetPasswordAskedInCurrentSession: boolean;
}

export const initialState: UserState = {
  loading: false,
  loginObj: {
    auth_token: '',
    is_admin: false,
    message: '',
    status: '',
    userType: -1,
    pendingPolicy: [],
  },
  existingUserObj: {
    message: '',
    status: '',
  },
  signUpObj: {
    auth_token: '',
    message: '',
    status: '',
  },
  forgotPasswordObj: {
    status: '',
    token: '',
  },
  loggedInUserObj: {
    admin: false,
    email: '',
    user_id: 0,
    user_name: '',
    user_type: 0,
    profile_pic_url: '',
  },
  productTours: [],
  visibleTours: [],
  tourLoadingStatus: 'not started',
  currentTour: {
    name: '',
    run: false,
    stepIndex: 0,
    steps: [],
  },
  productTours: [],
  visibleTours: [],
  tourLoadingStatus: 'not started',
  currentTour: {
    name: '',
    run: false,
    stepIndex: 0,
    steps: [],
  },
  showPolicy: false,
  resetPassword: false,
  resetPasswordAskedInCurrentSession: false,
};

export const UsersSlice = createSlice({
  name: 'login User',
  initialState,
  reducers: {
    logout: (state) => {
      Cookies.remove('loginObj');
      state.loginObj = { ...initialState.loginObj };
      state.loggedInUserObj = { ...initialState.loggedInUserObj };
      state.resetPasswordAskedInCurrentSession = false;
      // window.location.reload();
    },
    setResetPasswordAskedInCurrentSession: (state, action) => {
      state.resetPasswordAskedInCurrentSession = action.payload;
    },
    hidePolicy: (state) => {
      state.showPolicy = false;
    },
    setCurrentTour: (state, action) => {
      state.currentTour = action.payload;
    },
    tourMove: (state, action) => {
      state.currentTour.stepIndex += action.payload;
    },
    setVisibleTours: (state, action) => {
      const { tourName, isVisible } = action.payload;
      if (isVisible) {
        state.visibleTours = Array.from(new Set(state.visibleTours));
        state.visibleTours.push(tourName);
      } else {
        state.visibleTours = state.visibleTours.filter((vt) => vt !== tourName);
      }
    },
    closeVisibleTourNotification: (state) => {
      state.productTours = state.productTours.map((tr) =>
        state.visibleTours.includes(tr.tourName)
          ? { ...tr, notify: false }
          : tr,
      );
    },
    resetTour: (state) => {
      state.currentTour = {
        name: '',
        run: false,
        stepIndex: 0,
        steps: [],
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
        state.loginObj = initialState.loginObj;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        const { data } = action.payload;
        const { auth_token } = data;
        if (ENV === 'development') {
          Cookies.set('loginObj', JSON.stringify({ data: { auth_token } }), {
            expires: 7,
          });
        }
        state.loading = false;
        state.loginObj = action.payload.data;
        state.showPolicy = action.payload.data?.pendingPolicy ?? true;
        state.resetPassword = action.payload.data?.resetPassword ?? false;
        state.resetPasswordAskedInCurrentSession = action.payload.data
          ?.resetPassword
          ? false
          : true;
      })
      .addCase(loginUser.rejected, (state, payload) => {
        toast.error(payload.error.message, {
          autoClose: 1500,
        });
        state.loginObj = initialState.loginObj;
      })
      .addCase(checkExistingUser.pending, (state, payload) => {
        state.loading = true;
        state.existingUserObj = initialState.existingUserObj;
      })
      .addCase(checkExistingUser.fulfilled, (state, action) => {
        state.loading = false;
        state.existingUserObj = action.payload.data;
      })
      .addCase(checkExistingUser.rejected, (state, payload) => {
        toast.error(payload.error.message, {
          autoClose: 1500,
        });
        state.existingUserObj = initialState.existingUserObj;
      })
      .addCase(signUpUser.pending, (state, payload) => {
        state.loading = true;
        state.signUpObj = initialState.signUpObj;
      })
      .addCase(signUpUser.fulfilled, (state, action) => {
        state.loading = false;
        toast.success('New user created Successfully! ', {
          autoClose: 1500,
        });
        state.signUpObj = action.payload.data;
      })
      .addCase(signUpUser.rejected, (state, payload) => {
        toast.error(payload.error.message, {
          autoClose: 1500,
        });
        state.signUpObj = initialState.signUpObj;
      })
      .addCase(forgotPassword.pending, (state, payload) => {
        state.loading = true;
        state.forgotPasswordObj = initialState.forgotPasswordObj;
      })
      .addCase(forgotPassword.fulfilled, (state, action) => {
        toast.info(
          'An email has been sent to your email address with a link to reset your password',
          {
            autoClose: 1500,
          },
        );
        state.loading = false;
        state.forgotPasswordObj = action.payload.data;
      })
      .addCase(forgotPassword.rejected, (state, payload) => {
        toast.error(payload.error.message, {
          autoClose: 1500,
        });
        state.forgotPasswordObj = initialState.forgotPasswordObj;
      })
      .addCase(auth.pending, (state) => {
        state.loggedInUserObj = initialState.loggedInUserObj;
      })
      .addCase(auth.fulfilled, (state, action) => {
        state.loggedInUserObj = action.payload.data;
        const {email} = action.payload.data;
        if (ENV === 'production' && !!email) {
          zipy.identify(email);
        }
      })
      .addCase(authCheck.fulfilled, (state, action) => {
        state.loggedInUserObj = action.payload.data;
        const {email} = action.payload.data;
        if (ENV === 'production' && !!email) {
          zipy.identify(email);
        }
      })
      .addCase(getProductTours.pending, (state, action) => {
        state.tourLoadingStatus = 'started';
      })
      .addCase(getProductTours.fulfilled, (state, action) => {
        state.productTours = action.payload;
        state.tourLoadingStatus = 'completed';
      })
      .addCase(logoutUser.fulfilled, (state) => {
        Cookies.remove('loginObj');
        state.loginObj = initialState.loginObj;
        state.loggedInUserObj = initialState.loggedInUserObj;

        // reset state
        state = { ...initialState };
      })
      .addCase(addTourStatus.fulfilled, (state, action) => {
        const updatedTour = action.payload; // can be new tour or old one with updated value
        state.productTours = [
          ...state.productTours.filter(
            (tr) => tr.tourName !== updatedTour.tourName,
          ),
          updatedTour,
        ];
        state.currentTour = {
          name: '',
          run: false,
          stepIndex: 0,
          steps: [],
        };
      })
      .addCase(updateProfile.fulfilled, (state, action) => {
        const { profilePicUrl, userName } = action.payload as updateProfileProp;
        const authData = { ...state.loggedInUserObj };
        let updatedFields = {};

        if (!!userName) {
          state.loggedInUserObj.user_name = userName;
          updatedFields = { ...updatedFields, user_name: userName };
        }

        if (!!profilePicUrl) {
          state.loggedInUserObj.profile_pic_url = profilePicUrl;
          updatedFields = { ...updatedFields, profile_pic_url: profilePicUrl };
        }

        state.loggedInUserObj = { ...authData, ...updatedFields };

        toast.success('Profile Updated', {
          autoClose: 1500,
        });
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        toast.success('Password Updated', {
          autoClose: 1500,
        });
      })
      .addCase(resetPassword.rejected, (state) => {
        toast.error('Incorrect Password Provided', {
          autoClose: 1500,
        });
      });
  },
});

export const { actions: usersActions } = UsersSlice;
export default UsersSlice.reducer;
